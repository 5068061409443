import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin } from '#app';
import * as pkg from '~/package.json';

export default defineNuxtPlugin(nuxtApp => {
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const router = useRouter();

  if (sentry.environment !== 'production') {
    console.log('Release:', `${pkg.name}@${pkg.version}`, sentry.environment);
    console.log('DSN:', sentry.dsn || 'No dsn link provided');
    console.log('Environment:', sentry.environment || 'No sentry env provided');
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release: `${pkg.name}@${pkg.version}`,
    environment: sentry.environment,

    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//],
      }),
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        useCompression: false,
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [
          'localhost:3080',
          'localhost:8000',
          /^http:\/\/localhost:3080\//,
          /^https:\/\/disk\.denginadom\.ru\//,
          /^https:\/\/api-arm\.denginadom\.ru\//,
        ],
      }),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],

    tracePropagationTargets: [
      'localhost:3080',
      'localhost:8000',
      /^http:\/\/localhost:3080\//,
      /^https:\/\/disk\.denginadom\.ru\//,
      /^https:\/\/api-arm\.denginadom\.ru\//,
    ],
    tracesSampleRate: 1, // Change in prod

    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary

    debug: false,

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    },
  });
});
