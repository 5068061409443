import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';

export const pages = {
  login: '/login',
  index: '/',
};

export const settings = {
  login: {
    fields: {
      login: 'user_code',
      password: 'user_pass',
    },
  },
};

export const authFieldsMap = {
  user: 'data',
};

export const useAuthStore = defineStore(
  'auth',
  () => {
    const router = useRouter();
    const { $api } = useNuxtApp();
    const isLoggedIn = ref(false);
    const user = ref({});

    const reset = () => {
      user.value = {};
      isLoggedIn.value = false;
    };

    const login = async payload => {
      try {
        const { data } = await $api.web.login({
          [settings.login.fields.login]: payload[settings.login.fields.login],
          [settings.login.fields.password]: payload[settings.login.fields.password],
        });

        Object.assign(user.value, { user_guid: data.data.user_guid });
        Sentry.setUser({ username: data.data.user_name, id: data.data.user_guid });

        if (Number(data.data.pass_update_required) !== 1) {
          user.value = data[authFieldsMap.user];
          isLoggedIn.value = true;
          if (payload.redirect) {
            router.push(pages.index);
          }
        }
        return data.data;
      } catch (err) {
        reset();
        throw err;
      }
    };

    const logout = async instance => {
      try {
        if (instance) {
          await instance.get('/serviceSales/Logout');
        } else {
          await $api.web.logout();
        }
      } catch (err) {
        console.log(err);
      } finally {
        reset();
        router.push(pages.login);
      }
    };

    const isReallyLoggedIn = computed(() => {
      return Object.keys(user.value).length && isLoggedIn.value;
    });

    return {
      settings,
      isLoggedIn,
      login,
      logout,
      reset,
      user,
      isReallyLoggedIn,
    };
  },
  {
    persist: {
      debug: true,
      storage: persistedState.localStorage,
    },
  },
);
