export default (o, instance) => {
  return {
    getFiles() {
      return instance(o).get('/files/list');
    },
    uploadFiles({ number, data, field, config }) {
      return instance(o).post(`/${number}?field=${field}`, data, config);
    },
  };
};
