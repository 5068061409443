<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
useHead({
  htmlAttrs: {
    'data-theme': 'light',
    class: 'light',
  },
});
</script>
