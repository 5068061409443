export default (с, instance) => {
  return {
    login(data) {
      return instance(с).post('/serviceSales/Login', data);
    },
    logout() {
      return instance(с).get(`/serviceSales/Logout`);
    },
    updatePassword(data) {
      return instance(с).post(`/serviceSales/UpdateUserInfo`, data);
    },
    getTasksList() {
      return instance(с).get('/serviceSales/GetMOPTasks');
    },
  };
};
