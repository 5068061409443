import axios from 'axios';
import { useAuthStore } from '~/stores/auth';

const instances = {
  web: _ => {
    const authStore = useAuthStore();
    const instance = axios.create({
      baseURL: _.public.url.arm,
      headers: {
        'user-guid': authStore.user?.user_guid,
      },
      withCredentials: true,
    });

    const interceptor = instance.interceptors.response.use(
      r => r,
      async err => {
        if (err.response?.status && err.response.status === 401) {
          instance.interceptors.response.eject(interceptor);
          await authStore.logout(instance);
        }
        throw err;
      },
    );

    return instance;
  },

  disk: _ => {
    const authStore = useAuthStore();
    return axios.create({
      baseURL: `${_.public.url.disk}/upload/`,
      headers: {
        'user-guid': authStore.user?.user_guid,
        'Content-Type': 'multipart/formdata',
      },
    });
  },
};

export default instances;
