import { AxiosError } from 'axios';
import { useNotieStore } from '~/stores/notifications';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, ctx) => {

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.log(
        '%cError 🤡🤡🤡\n',
        'color: white; font-size: 16px;',
        error,
        '\n',
        '\n',
        '⬆️⬆️⬆️ ERROR CONTEXT ⬆️⬆️⬆️\n',
        ctx,
      );
    }

    if (error instanceof AxiosError) {
      const notieStore = useNotieStore();

      notieStore.addNotification({
        type: 'error',
        message: error?.response?.data?.message || 'Что-то пошло не так',
      });
    }

  };
});
