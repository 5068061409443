import instances from './instances';
import web from './web';
import disk from './disk';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  return {
    provide: {
      api: {
        web: web(config, instances.web),
        disk: disk(config, instances.disk),
      },
    },
  };
});
