export default defineNuxtRouteMiddleware((to, from) => {
  try {
    const permittedRoutes = ['/login'];
    // const authContainer = useCookie('auth')?.value;
    const authContainer = JSON.parse(localStorage.getItem('auth'));

    if (
      permittedRoutes.includes(to.path) &&
      authContainer &&
      authContainer?.isLoggedIn &&
      authContainer?.user
    ) {
      return navigateTo('/');
    }
    if (
      from.path !== '/login' &&
      !permittedRoutes.includes(to.path) &&
      (!authContainer || !authContainer?.isLoggedIn || !authContainer?.user)
    ) {
      return navigateTo('/login');
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
});
